<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col cols="5">
                    <b-col cols="12">
                      <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="1.Đợt xét tuyển:"
                        label-for="admissionRoundSelected"
                      >
                        <v-select
                          v-model="filter.admissionRoundId"
                          :options="admissionRounds"
                          :reduce="(option) => option.value"
                          :clearable="false"
                          placeholder="1.Đợt xét tuyển"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="2.Phương thức xét tuyển:"
                        label-for="admissionFormSelected"
                      >
                        <v-select
                          v-model="filter.admissionFormId"
                          :options="dataListAdmissionForm"
                          :reduce="(option) => option.value"
                          :clearable="false"
                          placeholder="2.Phương thức xét tuyển"
                        />
                      </b-form-group>
                    </b-col>
                </b-col>
                <b-col cols="7">
                      <b-form-group
                        label-cols="2"
                        label-cols-lg="2"
                        label="3.Môn thi:"
                        label-for="admissionSubjectSelected"
                      >
                        <v-select
                          v-model="filter.admissionSubjectId"
                          :options="admissionSubjectOptions"
                          :reduce="(option) => option.value"
                          :clearable="false"
                          placeholder="3.Chọn môn thi:"
                        />
                      </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="w-100 d-flex justify-content-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="getExaminationRoomsFromStore"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="SearchIcon" /> Tìm kiếm
                      </span>
                    </b-button>

                    <b-button
                      v-if="scoreLockUpdatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mr-1"
                      @click="onSaveLockScore"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu khóa
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="examinationRooms"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-column"
                    slot-scope="props"
                  >
                    <span v-if="props.column.label === 'Khóa điểm'">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-form-checkbox
                          v-model="selectedAll"
                          :value="1"
                          :unchecked-value="0"
                        />
                      </div>
                    </span>
                    <span v-else>
                      {{ props.column.label }}
                    </span>
                  </template>

                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'scoreLock'">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-form-checkbox
                          v-model="props.row.scoreLock"
                          :value="1"
                          :unchecked-value="0"
                          @change="
                            changeLock(
                              props.row.scoreLock,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </div>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{
                      props.formattedRow[props.column.field]
                    }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span
                          class="text-nowrap"
                        >của {{totalRows}} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

    <InputPassword @succeed="onSaveLockScore" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { SCORE_LOCKS } from '@/const/status'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import InputPassword from '@/views/score-lock/InputPassword.vue'

export default {
  name: 'ScoreLockExamination',
  directives: {
    Ripple,
  },
  components: {
    InputPassword,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BForm,
    BFormGroup,
    BFormCheckbox,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: null,
        currentPage: 1,
        itemsPerPage: 10,
        admissionRoundId: null,
        admissionFormId: null,
        admissionSubjectId: null,
        admissionExaminationRoomName: '',
        admissionSubjectName: '',
        scoreLock: null,
        sort: null,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Phòng thi',
          field: 'admissionExaminationRoomName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập phòng thi',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Môn thi',
          field: 'admissionSubjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập môn thi',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Đợt xét tuyển',
          field: 'admissionRoundName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Phương thức xét tuyển',
          field: 'admissionFormName',
          sortable: false,
          thClass: 'text-center',
        },

        {
          label: 'Khóa điểm',
          field: 'scoreLock',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: SCORE_LOCKS,
            placeholder: 'Chọn trạng thái',
          },
          width: '10%',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      originExaminationRooms: [],
      selectedAll: 0,
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters({
      examinationRooms: 'admissionExaminationRoom/examinationRooms',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionExaminationRoom/admissionForms',
      listAdmissionSubject: 'admissionExaminationRoom/listAdmissionSubject',
    }),
    scoreLockUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SCORE_LOCK_EXAMINATION_ROOM)
        && this.user.scoreLockPassword > 0
    },
    examinationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_EXAMINATION_POINT)
    },
    admissionSubjectOptions() {
      return this.listAdmissionSubject.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
  },
  watch: {
    selectedAll(val) {
      let data = []
      if (val === 1) {
        data = this.examinationRooms.map(elm => ({ ...elm, scoreLock: 1 }))
      } else if (val === 0) {
        data = this.examinationRooms.map(elm => ({ ...elm, scoreLock: 0 }))
      } else {
        data = this.examinationRooms
      }
    },

    'filter.admissionRoundId': async function (val) {
      await this.readAdmissionFormByAdmissionRoundId({
        admissionRoundId: this.filter.admissionRoundId
      })
    },

    'filter.admissionFormId': async function (val) {
      await this.getAdmissionSubject({
        admissionRoundId: this.filter.admissionRoundId,
        admissionFormId: this.filter.admissionFormId || val,
      })
    },

  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      if (this.admissionRoundId) {
        this.admissionRoundSelected = this.admissionRoundId
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      readAdmissionFormByAdmissionRoundId: 'admissionExaminationRoom/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getScoreLockAdmissionExaminationRooms: 'admissionExaminationRoom/getScoreLockAdmissionExaminationRooms',
      updateScoreLockAdmissionExaminationRooms: 'admissionExaminationRoom/updateScoreLockAdmissionExaminationRooms',
      getAdmissionSubject: 'admissionExaminationRoom/getAdmissionSubject',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getExaminationRoomsFromStore()
    },
    async getExaminationRoomsFromStore() {
      this.isLoading = true
      try {
        await this.getScoreLockAdmissionExaminationRooms(this.filter)
        this.totalRows = this.examinationRooms.length
        this.originExaminationRooms = this.examinationRooms.map(elm => ({
          ...elm,
        }))
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getExaminationRoomsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'admissionExaminationRoomName') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getExaminationRoomsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'admissionExaminationRoomName')) {
        this.updateParams({
          admissionExaminationRoomName: columnFilters.admissionExaminationRoomName,
        })
      }
      if (Object.hasOwn(columnFilters, 'admissionSubjectName')) this.updateParams({ admissionSubjectName: columnFilters.admissionSubjectName })
      if (Object.hasOwn(columnFilters, 'scoreLock')) this.updateParams({ scoreLock: columnFilters.scoreLock })
      await this.getExaminationRoomsFromStore()
    },
    onResetFilter() {
      this.updateParams({
        trainingSystemId: null,
        admissionRoundId: null,
        admissionFormId: null,
        scoreLock: null,
      })
    },
    changeLock(data, column, row) {
      this.examinationRooms[row][column] = data
      if (this.examinationRooms.filter(element => element.scoreLock === 1).length === this.examinationRooms.length) {
        this.selectedAll = 1
      } else {
        this.selectedAll = 2
      }
    },
    async onSaveLockScore(password) {
      const scoreLocks = this.examinationRooms
        .filter(o1 => this.originExaminationRooms.some(
          o2 => o1.admissionExaminationRoomId === o2.admissionExaminationRoomId
              && o1.scoreLock !== o2.scoreLock,
        ))
        .map(element => ({
          id: element.admissionExaminationRoomId,
          isScoreLocked: element.scoreLock,
        }))
      if (scoreLocks.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng thay đổi khóa điểm phòng thi',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const { isSuccessful, message } = await this.updateScoreLockAdmissionExaminationRooms({ password, scoreLocks })
        if (isSuccessful) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('inputPasswordModal')
          await this.getExaminationRoomsFromStore()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
